<template>
  <div>
    <vs-popup title="NOUVEAU INVENTAIRE" :active.sync="popupNouveau">
      <div class="vx-row">
        <div class="vx-col w-full mt-5">
          <vs-input class="w-full" type="date" label="Date" v-model="DateInventaire"></vs-input>
        </div>
        <div class="vx-col w-full mt-5">
          <vs-input class="w-full" type="text" label="Référence" :value="RefInventaire" @change.native="RefInventaire=$event.target.value"></vs-input>
        </div>
        <div class="vx-col w-full mt-5">
          <vs-select label="Categories à inventorier" autocomplete class="selectExample w-full select-large" v-model="Categorie">
            <vs-select-item :key="index" :value="item._id" :text="item.Libelle_Categ" v-for="(item,index) in categoriearticles" />
          </vs-select>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full text-center  mt-5">
          <vs-button class="mr-3 mb-2" @click="NouveauInventaire()">ENREGISTRER</vs-button>
          <vs-button color="warning" type="border" class="mb-2" @click="DateInventaire = RefInventaire = Categorie = null; popupNouveau = false;">ANNULER</vs-button>
        </div>
      </div>
    </vs-popup>
    <div class="vx-row mt-base">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col">
            <vs-button class="mt-5" color="primary" type="filled" @click="popupNouveau=true">Nouveau inventaire</vs-button>
          </div>
          <div class="vx-col">
            <vs-select label="Inventaires" autocomplete class="selectExample w-full select-large" v-model="inventaireId" @change="initInventaire(inventaireId)">
              <vs-select-item :key="index" :value="item._id" :text="item.RefInventaire" v-for="(item,index) in inventaires" />
            </vs-select>
          </div>
          <div class="vx-col" v-if="inventaireId">
            <vs-button class="mt-5" color="primary" type="filled" @click="updateInventaire">SAUVEGARDER</vs-button>
          </div>
          <div class="vx-col" v-if="inventaireId">
            <vs-button class="mt-5" color="primary" type="filled" @click="updateInventaireToStock">Mise à jour du stock</vs-button>
          </div>
          <div class="vx-col w-full mt-5">
            <vs-table search :data="ArticleInventorier" class="w-full" stripe >
              <template slot="thead">
                <vs-th>Etiquette</vs-th>
                <vs-th>Catégorie</vs-th>
                <vs-th>Type</vs-th>
                <vs-th>Modèle</vs-th>
                <vs-th>Marque</vs-th>
                <vs-th>P.U.</vs-th>
                <vs-th>Qte théo.</vs-th>
                <vs-th>Qte Phys.</vs-th>
                <vs-th>Ecart</vs-th>
                <vs-th>Observation</vs-th>
                <vs-th></vs-th>
              </template>

              <template v-if="inventaireId" slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    {{tr.Etiquette}}
                  </vs-td>

                  <vs-td>
                    <cell-renderer-categorie :categorieId="tr.IdCateg"></cell-renderer-categorie>
                  </vs-td>

                  <vs-td>
                    <cell-renderer-type :typeId="tr.IdTypeArt"></cell-renderer-type>
                  </vs-td>
                  <vs-td>
                    {{tr.Modele}}
                  </vs-td>
                  <vs-td>
                    <cell-renderer-marque :marqueId="tr.IdMarque"></cell-renderer-marque>
                  </vs-td>
                  <vs-td>
                    {{tr.PrixVenteTTC}}
                  </vs-td>
                  <vs-td>
                    {{tr.stock}}
                  </vs-td>
                  <vs-td>
                    <vs-input type="number" :value="tr.qtePhys" @change.native="tr.qtePhys=$event.target.value"></vs-input>
                  </vs-td>
                  <vs-td>
                    {{tr.stock - (tr.qtePhys ? tr.qtePhys : 0)}}
                  </vs-td>
                  <vs-td>
                    <vs-input type="text" :value="tr.observation" @change.native="tr.observation=$event.target.value"></vs-input>
                  </vs-td>
                  <vs-td>

                  </vs-td>
                </vs-tr>
              </template>

            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>

  </div>

</template>

<script>
import vSelect from 'vue-select'

// Store Module

// Cell Renderer
import CellRendererMarque from './cell-renderer/CellRenderMarque.vue'
import CellRendererCategorie from './cell-renderer/CellRendererCategorie.vue'
import CellRendererType from './cell-renderer/CellRendererType.vue'


export default {
  components: {
    vSelect,

    // Cell Renderer
    CellRendererMarque,
    CellRendererCategorie,
    CellRendererType
  },
  data () {
    return {
      activeUserInfos: null,
      IdOfficine: '',
      DateInventaire: null,
      RefInventaire: '',
      Categorie: '',
      ArticleInventorier: [],
      popupNouveau: false,
      inventaireId: null
    }
  },


  computed: {
    officines () {
      return this.$store.state.officine.officines
    },
    stocks () {
      return this.$store.state.stock.articles
    },
    categoriearticles () {
      return this.$store.state.categorie_article.categorie_articles
    },
    inventaires () {
      return this.$store.state.inventaire.inventaires
    }
  },
  methods: {
    NouveauInventaire () {
      const filtrestock = this.$store.state.stock.articles ? this.$store.state.stock.articles.filter((a) => a.IdCateg === this.Categorie) : []

      filtrestock.forEach(stock => {
        const ligne = {
          idArticle: stock._id,
          Etiquette: stock.Etiquette,
          IdCateg: stock.IdCateg,
          IdTypeArt: stock.IdTypeArt,
          Modele: stock.Modele,
          IdMarque: stock.IdMarque,
          PrixVenteTTC: stock.PrixVenteTTC,
          stock: stock.stock,
          qtePhys: 0,
          observation: ''

        }

        this.ArticleInventorier.push(ligne)

      })

      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.activeUserInfos.IdPersonel,
        DateInventaire: this.DateInventaire,
        RefInventaire: this.RefInventaire,
        Categorie: this.Categorie,
        ArticleInventorier: this.ArticleInventorier

      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('inventaire/NouveauInventaire', payload)
        .then(() => {
          /* this.inventaireId = resp.data._id */
          this.popupNouveau = false
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.notify({
            title: 'error',
            text: `${error}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    updateInventaire () {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.activeUserInfos.IdPersonel,
        DateInventaire: this.DateInventaire,
        RefInventaire: this.RefInventaire,
        Categorie: this.Categorie,
        ArticleInventorier: this.ArticleInventorier,
        id: this.inventaireId

      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('inventaire/updateInventaire', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success !',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.notify({
            title: 'error',
            text: `${error}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    updateInventaireToStock () {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.activeUserInfos.IdPersonel,
        DateInventaire: this.DateInventaire,
        RefInventaire: this.RefInventaire,
        Categorie: this.Categorie,
        ArticleInventorier: this.ArticleInventorier,
        id: this.inventaireId

      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('inventaire/updateInventaireToStock', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success !',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'primary'
          })
          this.getActiveInventairesByOfficine()
          this.inventaireId = null
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${error}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    initInventaire (idInventaire) {
      console.log('id', idInventaire)
      const inventaire = this.$store.getters['inventaire/getInventaireById'](idInventaire)
      console.log(inventaire)
      this.ArticleInventorier = inventaire ? (inventaire.ArticleInventorier ? inventaire.ArticleInventorier : []) : []
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((error) => {
          console.log(error)
          this.$vs.notify({
            title: 'error',
            text: `${error}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getStocks () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('stock/getStocks')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
    },
    getStockByOfficine (id) {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('stock/getStockByOfficine', id)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
    },
    getCategorieArticle () {
      this.$store.dispatch('categorie_article/getCategorieArticles')
        .then(() => {

        })
        .catch((error) => {
          console.log(error)
          this.$vs.notify({
            title: 'error',
            text: `${error}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getTypes () {
      this.$store.dispatch('type/getTypes')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getMarques () {
      this.$store.dispatch('marque/getMarques')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.IdOfficine = this.activeUserInfos.IdOfficine

      this.getStockByOfficine(this.IdOfficine)
    },
    getActiveInventairesByOfficine () {

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('inventaire/getActiveInventairesByOfficine', this.IdOfficine)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.notify({
            title: 'error',
            text: `${error}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    }

  },
  created () {
    this.activeUserInfo()
    this.getActiveInventairesByOfficine()
    /*  this.getStocks() */
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
    if (!(this.$store.state.categorie_article.categorie_articles.length > 0)) this.getCategorieArticle()
    if (!(this.$store.state.type.types.length > 0)) this.getTypes()
    if (!(this.$store.state.marque.marques.length > 0)) this.getMarques()
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
